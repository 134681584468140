import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useAmp } from 'next/amp';

const SwagWrap = styled.div`
  img,
  amp-img {
    object-fit: cover;
    width: 100%;
    border-width: 1rem;
    border-style: solid;
  }

  &:nth-child(even) img,
  &:nth-child(even) amp-img {
    border-color: var(--green);
  }

  &:nth-child(odd) img,
  &:nth-child(odd) amp-img {
    border-color: var(--purple);
  }
`;

const SwagItem = ({ image, alt }) => {
  const isAmp = useAmp();
  return (
    <SwagWrap>
      {isAmp ? (
        <amp-img
          src={image}
          alt={alt}
          layout="responsive"
          height="400"
          width="400"
        />
      ) : (
        <img src={image} alt={alt} />
      )}
    </SwagWrap>
  );
};

SwagItem.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default SwagItem;
