import styled from 'styled-components';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  secondary?: boolean;
}

export const ButtonWrapper = styled.div`
  text-align: center;
  margin: 5rem 0;
  padding: 2rem 0;
`;

export const Button = styled.a<Pick<Props, 'secondary'>>`
  padding: 2rem 2.5rem;
  color: ${props => (props.secondary ? 'var(--dark-purple)' : 'white')};
  font-size: 1.8rem;
  letter-spacing: 0.2rem;
  background: ${props => (props.secondary ? 'var(--green)' : 'var(--purple)')};
  text-decoration: none;
  transition: all 200ms cubic-bezier(0.85, -0.12, 0, 4.13);
  cursor: pointer;

  &:hover {
    padding: 2rem 3rem;
  }
`;

export const ButtonText = styled.button`
  display: block;
  color: var(--button-text);
  padding: 0;
  margin: 5rem auto 0;
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  font-size: 1.6rem;
`;

Button.defaultProps = {
  secondary: false,
};
