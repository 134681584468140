import React, { useState } from 'react';

import { H1, P } from '../typography';
import { ButtonWrapper, ButtonText, Button } from '../button';
import Episode from '../episode';

import { EpisodeProps } from '~/types';

const Episodes = ({ episodes }: { episodes: EpisodeProps[] }) => {
  const [episodesToShow, setEpisodes] = useState(() => episodes.slice(0, 6));
  const [allVisible, setAllVisible] = useState(
    () => episodesToShow.length === episodes.length
  );

  const addMoreEpisodes = () => {
    const currentEpLength = episodesToShow.length;
    const moreEps = episodes.slice(0, currentEpLength + 6);
    setEpisodes(moreEps);
    setAllVisible(moreEps.length === episodes.length);
  };

  return (
    <section id="episodes">
      <H1>2. Recent episodes, in your ear holes.</H1>
      <P>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur.
      </P>

      {episodesToShow.map((episode, index) => (
        <Episode
          key={episode.id}
          id={episode.id}
          audio={episode.audio}
          date={episode.date}
          image={episode.image}
          imageAlt={episode.episodeTitle}
          episodeNumber={episode.episodeNumber}
          episodeTitle={episode.episodeTitle}
          showNotes={episode.showNotes}
          shouldShowNotes={index === 0}
          link={episode.link}
        />
      ))}

      <ButtonWrapper>
        <Button href={process.env.ITUNES}>Join our newsletter</Button>
        {!allVisible && (
          <ButtonText onClick={addMoreEpisodes}>Load more episodes</ButtonText>
        )}
      </ButtonWrapper>
    </section>
  );
};

export default Episodes;
