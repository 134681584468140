import React from 'react';

import { H1, P } from '../typography';
import SwagItem from '../swag-item';
import { ButtonWrapper, Button } from '../button';
import Grid from '../photo-grid';

const Swag = () => (
  <section id="swag">
    <H1>4. Want a shirt or a sticker? Get one!</H1>

    <P>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur.
    </P>

    <Grid gap="1rem">
      {[...Array(4).keys()].map(item => (
        <SwagItem
          key={item}
          image="/static/img/Trav_and_Los_avatar_Final.png"
          alt="Trav and Los logo"
        />
      ))}
    </Grid>

    <ButtonWrapper>
      <Button href="#swag">Shop</Button>
    </ButtonWrapper>
  </section>
);

export default Swag;
