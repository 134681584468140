import React from 'react';
import { NextPage, GetStaticProps } from 'next';

import About from '../components/sections/about';
import Episodes from '../components/sections/episodes';
import Reviews from '../components/sections/reviews';
import Swag from '../components/sections/swag';

import { EpisodeProps, ReviewProps } from '~/types';
import { getReviews } from '~/lib/get-reviews';
import { getEpisodes } from '~/lib/get-episodes';

interface Props {
  episodes: EpisodeProps[];
  reviews: ReviewProps[];
}

export const getStaticProps: GetStaticProps<Props> = async () => {
  const [reviews, episodes] = await Promise.all([getReviews(), getEpisodes()]);
  return { props: { episodes, reviews }, unstable_revalidate: 86400 };
};

const Index: NextPage<Props> = ({
  episodes,
  reviews,
}: {
  episodes: EpisodeProps[];
  reviews: ReviewProps[];
}) => (
  <>
    <About />
    <Episodes episodes={episodes} />
    <Reviews reviews={reviews} />
    <Swag />
  </>
);

export default Index;
