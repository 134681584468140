import PropTypes from 'prop-types';
import styled from 'styled-components';

export const H1 = styled.h1`
  font-size: 3.2rem;
  margin-bottom: 3rem;
  color: var(--text);
`;

export const P = styled.p`
  font-size: 1.6rem;
  margin-bottom: 3rem;
  line-height: 1.8;
  color: var(--text);
`;

const propTypes = {
  children: PropTypes.node.isRequired,
};

H1.propTypes = propTypes;

P.propTypes = propTypes;
