import React from 'react';
import Head from 'next/head';
import { useAmp } from 'next/amp';
import styled from 'styled-components';

interface Props {
  title: string;
  source: string;
  height: number;
  width: number;
}

const Wrap = styled.div<{ wrapHeight: number; wrapWidth: number }>`
  position: relative;
  height: 0;
  margin: 5rem 0;
  padding-top: ${props => (props.wrapHeight / props.wrapWidth) * 100}%;

  iframe,
  amp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Embed = ({ height, width, source, title }: Props) => {
  const isAmp = useAmp();
  return (
    <>
      {isAmp && (
        <Head>
          <script
            async
            custom-element="amp-iframe"
            src="https://cdn.ampproject.org/v0/amp-iframe-0.1.js"
          />
        </Head>
      )}
      <Wrap wrapHeight={height} wrapWidth={width}>
        {isAmp ? (
          <amp-iframe
            width={width}
            height={height}
            title={title}
            layout="responsive"
            sandbox="allow-scripts allow-same-origin allow-popups allow-presentation"
            frameBorder="0"
            src={source}
          />
        ) : (
          <iframe
            title={title}
            width={width}
            height={height}
            src={source}
            frameBorder="0"
            allowFullScreen
          />
        )}
      </Wrap>
    </>
  );
};

export { Embed };
