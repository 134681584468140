import styled from 'styled-components';
import { string } from 'prop-types';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 40rem);
  grid-gap: ${props => props.gap};

  @media (max-width: 850px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
`;

Grid.propTypes = {
  gap: string,
};

Grid.defaultProps = {
  gap: '0',
};

export default Grid;
