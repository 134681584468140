import React from 'react';
import styled from 'styled-components';

import Star from '../icons/star.svg';

import { ReviewProps } from '~/types';

const makeDate = new Intl.DateTimeFormat('en', {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
});

const Wrap = styled.div.attrs({
  className: 'review',
})`
  color: var(--review-text);
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem;

  @media (max-width: 460px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Stars = styled.div`
  margin: 0 2rem;
  flex-grow: 1;
  min-width: 12rem;

  @media (max-width: 460px) {
    margin: 1rem 0;
  }

  @media (max-width: 533px) {
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;

const Title = styled.h1`
  font-weight: normal;
  color: var(--review-title);
  font-size: 1.6rem;
`;

const Author = styled.p`
  margin: 0;
  font-size: 1.6rem;
`;

const Text = styled.p`
  margin: 2rem 0 3rem 0;
  line-height: 1.8;
  font-size: 1.6rem;
`;

const stars = (rating: number) => {
  const array = Array.from({ length: rating }, (x, i) => i);
  return array.map(star => (
    <Star fill="var(--star)" key={star} title={`Star ${star} of ${rating}`} />
  ));
};

const Review = ({
  title,
  rating,
  source,
  updated,
  author,
  content,
}: ReviewProps) => (
  <Wrap>
    <Details>
      <Title>{title}</Title>
      <Stars title={`Rated ${rating} out of 5 stars`}>{stars(rating)}</Stars>
      <div>
        {source} - {makeDate.format(new Date(updated))}
      </div>
    </Details>
    <Author>by {author}</Author>
    <div>
      <Text>{content}</Text>
    </div>
  </Wrap>
);

export default Review;
