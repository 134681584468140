import React from 'react';

import { H1, P } from '../typography';
import { ButtonWrapper, Button } from '../button';
import Review from '../review';

import { ReviewProps } from '~/types';

interface Props {
  reviews: ReviewProps[];
}

const Reviews = ({ reviews }: Props) => (
  <section id="donate">
    <H1>3. Support the show!</H1>
    <P>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur.
    </P>

    {reviews.map(review => (
      <Review
        key={review.id}
        id={review.id}
        title={review.title}
        rating={review.rating}
        source="iTunes Review"
        author={review.author}
        content={review.content}
        updated={review.updated}
      />
    ))}

    <ButtonWrapper>
      <Button href="#">Donate to the show</Button>
    </ButtonWrapper>
  </section>
);

export default Reviews;
